import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import Cities from "../../data/cities.json";
import { BLACK_COLOR, TEXT_COLOR_2, WHITE_COLOR } from "../Variables";
import CheckBox from "../elements/CheckBox";
import { Button, InputText } from "../elements/SmallElements";
export default function Filters({
  searchTerm,
  onSearchChange,
  cityValue,
  onCityChange,
  contractValue,
  onContractChange,
  tagsValues,
  onTagsChange,
  resetFilter,
}) {
  // FOR CITY
  const animatedComponents = makeAnimated();
  const ville = Cities.cities.data;
  const villeOptions = ville.map((city) => ({
    value: city.names.fr,
    label: city.names.fr,
  }));
  // FOR CONTRACT
  const filterByContract = ["ANAPEC", "CDD", "CDI", "Freelance", "Stage"];
  // FOR TAGS
  const FilterByTags = [
    "#SQL",
    "#Java",
    "#HTML",
    "#CSS",
    "#JAVASCRIPT",
    "#.NET",
    "#Reactjs",
    "#Vuejs",
    "#Angular",
    "#Nodejs",
  ];

  return (
    <>
      <div className="TEXT_COLOR_1 fs-5 mb-4">Filtrez Par</div>
      <Container>
        <AccordionStyled defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Mots-clés</Accordion.Header>
            <Accordion.Body>
              <InputText
                type="text"
                placeholder="Recherche d’emplois, titre, etc..."
                className="w-100"
                value={searchTerm}
                onChange={onSearchChange}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Localisation</Accordion.Header>
            <Accordion.Body>
              <Select
                placeholder="Choisir une ville"
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={villeOptions}
                value={cityValue}
                onChange={onCityChange}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Contract</Accordion.Header>
            <Accordion.Body>
              <ul>
                {filterByContract.map((childItem, childIndex) => (
                  <li key={childIndex} className="d-flex align-items-center">
                    <CheckBox
                      ID={childItem}
                      value={childItem}
                      checked={contractValue.includes(childItem)}
                      onChange={onContractChange}
                    />
                    <label htmlFor={childItem}>{childItem}</label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Compétences</Accordion.Header>
            <Accordion.Body>
              <ul>
                {FilterByTags.map((childItem, childIndex) => (
                  <li key={childIndex} className="d-flex align-items-center">
                    <CheckBox
                      ID={childItem}
                      value={childItem}
                      checked={tagsValues.includes(childItem)}
                      onChange={onTagsChange}
                    />
                    <label htmlFor={childItem}>
                      {childItem.startsWith("#")
                        ? childItem.slice(1)
                        : childItem}
                    </label>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </AccordionStyled>
        <div className="pt-4">
          <Button
            text="Réinitialiser Filtrer"
            url="#"
            newTab="no"
            className="d-inline-block w-100 text-center"
            onClick={resetFilter}
          />
        </div>
      </Container>
    </>
  );
}

const AccordionStyled = styled(Accordion)`
  .accordion-button {
    padding: 20px 0;
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 500;
    outline: none;
  }
  .accordion-button:not(.collapsed) {
    color: ${BLACK_COLOR};
    background-color: transparent;
    box-shadow: none;
  }
  .accordion-button:focus {
    border-color: none;
    box-shadow: none;
  }
  .accordion-item {
    color: ${BLACK_COLOR};
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid rgba(49, 121, 90, 0.3);
  }
  .accordion-body {
    padding: 0 0 20px 0;
  }
  ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 0;
    li label {
      margin: 0;
      font-size: 16px;
      padding-left: 10px;
      color: ${TEXT_COLOR_2};
    }
  }
`;

const Container = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 40px;
  padding: 15px 25px 25px 25px;
  position: sticky;
  top: 140px;
`;
