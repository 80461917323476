import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { BOX_SHADOW, WHITE_COLOR } from "../components/Variables";
import Form from "../components/contact/Form";
import { Section, SideBar } from "../components/elements/SmallElements";
import Bottom from "../components/shapes/Bottom";
import { UseScrollToTop } from "../components/elements/UseScrollToTop";

export default function Contact() {
  UseScrollToTop();
  return (
    <>
      <Header home={false} title="Contactez-nous" page="Contact" />
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni
                explicabo sequi saepe sed nobis sit, architecto praesentium
                omnis facere maxime iure itaque neque eveniet! Fugiat aliquid ab
                placeat perferendis quod!
              </p>
            </div>
            <div className="col-12 col-lg-5">
              <SideBar>
                <WithBox style={{ marginTop: "-250px" }}>
                  <h2 className="fs-4 mb-4">
                    Envoyez-nous un message
                    <i class="fa-solid fa-paper-plane ms-3 TEXT_PRIMARY"></i> 
                  </h2>
                  <Form />
                </WithBox>
              </SideBar>
            </div>
          </div>
        </div>
        <Bottom></Bottom>
      </Section>
      <Footer />
    </>
  );
}

const WithBox = styled.div`
  background-color: ${WHITE_COLOR};
  padding: 30px;
  border-radius: 40px;
  -webkit-box-shadow: ${BOX_SHADOW};
  -moz-box-shadow: ${BOX_SHADOW};
  box-shadow: ${BOX_SHADOW};
`;
