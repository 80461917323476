import { useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { TEXT_COLOR_1, WHITE_COLOR } from "../components/Variables";
import { Section } from "../components/elements/SmallElements";
import DataFiltered from "../components/offers/DataFiltered";
import Filters from "../components/offers/Filters";
import Bottom from "../components/shapes/Bottom";
import { useOffersData } from "../context/OffersDataProvider";
import { UseScrollToTop } from "../components/elements/UseScrollToTop";

export default function Offers() {
  UseScrollToTop();
  const url = new URL(window.location.href);
  const searchParam = url.searchParams.get("search");
  const { data, error } = useOffersData();
  const [openSideBar, setOpenSideBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchParam || "");
  const [cityValues, setCityValues] = useState([]);
  const [contractValues, setContractValues] = useState([]);
  const [tagsValues, setTagsValues] = useState([]);
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  // FOR SEARCH
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  // FOR CITY
  const handleCityChange = (event) => {
    setCityValues(event);
  };
  // FOR CONTRACT
  const handleContractChange = (selectedOptions) => {
    setContractValues((prevFilters) => {
      if (prevFilters.includes(selectedOptions.target.value))
        return prevFilters.filter(
          (filter) => filter !== selectedOptions.target.value
        );
      else return [...prevFilters, selectedOptions.target.value];
    });
  };
  // FOR TAGS
  const handleTagsChange = (selectedOptions) => {
    setTagsValues((prevFilters) => {
      if (prevFilters.includes(selectedOptions.target.value))
        return prevFilters.filter(
          (filter) => filter !== selectedOptions.target.value
        );
      else return [...prevFilters, selectedOptions.target.value];
    });
  };
  // DATA FILTERD
  const filterData = () => {
    return data.filter((item) => {
      const searchMatch = item?.title
        ?.toLowerCase()
        ?.trim()
        ?.includes(searchTerm.toLowerCase().trim());
      const cityMatch =
        cityValues.length === 0 ||
        cityValues.some((city) => city.value === item.info.location);
      const contractMatch =
        contractValues.length === 0 ||
        contractValues.includes(item.info.contract);
      const tagsMatch =
        tagsValues.length === 0 ||
        item.tags.some((tag) => tagsValues.includes(tag));

      return searchMatch && cityMatch && contractMatch && tagsMatch;
    });
  };
  //REST FILTER
  const hendleRestFilter = () => {
    setSearchTerm("");
    setCityValues([]);
    setContractValues([]);
    setTagsValues([]);
  };
  // FOE SIDEBAR
  const handleOpenSideBard = () => {
    setOpenSideBar(true);
    document.body.style.overflow = "hidden";
  };
  const handleCloseSideBard = () => {
    setOpenSideBar(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <Header
        home={false}
        title="Offres d'emploi actives"
        page="Offres d'emploi"
      />
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row">
            <Open
              className="col-11 col-sm-12 mx-auto mb-4 d-lg-none"
              onClick={handleOpenSideBard}
            >
              <i className="fa-solid fa-filter me-2"></i>
              Filtrez Par
            </Open>
            <SideBar
              className={`col-6 col-lg-4 col-xl-3 ${
                openSideBar ? "active" : ""
              }`}
            >
              <Close className="d-lg-none" onClick={handleCloseSideBard}>
                <i className="fa-solid fa-circle-xmark"></i>
              </Close>
              <Filters
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                cityValue={cityValues}
                onCityChange={handleCityChange}
                contractValue={contractValues}
                onContractChange={handleContractChange}
                tagsValues={tagsValues}
                onTagsChange={handleTagsChange}
                resetFilter={hendleRestFilter}
              />
            </SideBar>
            <div className="col-12 col-lg-8 col-xl-9">
              <DataFiltered data={filterData()} />
            </div>
          </div>
        </div>
        <Bottom />
      </Section>
      <Footer />
    </>
  );
}

const SideBar = styled.div`
  @media screen and (max-width: 992px) {
    padding: 10px 10px 20px;
    position: fixed;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    &.active {
      left: 0;
    }
  }
`;

const Open = styled.button`
  border: none;
  background-color: ${WHITE_COLOR};
  border-radius: 10px;
  padding: 10px 0;
  color: ${TEXT_COLOR_1};
  font-size: 18px;
`;

const Close = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  width: 40px;
  height: 40px;
  font-size: 28px;
  color: ${WHITE_COLOR};
  border: none;
  outline: none;
`;
