import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { REACT_APP_API_URL } from "../components/Variables";

const OffersDataContext = createContext();

export const OffersDataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/getAllJobsData`
      );
      const realData = response.data.filter(
        (item) => item.name !== "TimeoutError"
      );
      setData(realData);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <OffersDataContext.Provider value={{ data, error }}>
        {children}
      </OffersDataContext.Provider>
    </>
  );
};

export const useOffersData = () => {
  const context = useContext(OffersDataContext);
  if (!context) {
    throw new Error("useOffersData must be used within a OffersDataProvider");
  }
  return context;
};
