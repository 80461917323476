import { createBrowserRouter } from "react-router-dom";
import { UseScrollToTop } from "../components/elements/UseScrollToTop";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";
import Company from "../pages/Company";
import CompanyDetail from "../pages/CompanyDetail";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import OfferDetail from "../pages/OfferDetail";
import Offers from "../pages/Offers";
import Quiz from "../pages/Quiz";

const Layout = ({ children }) => {
  UseScrollToTop();
  return children;
};

const Router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/offres-emploi",
    element: (
      <Layout>
        <OffersDataProvider>
          <Offers />
        </OffersDataProvider>
      </Layout>
    ),
  },
  {
    path: "/offres-emploi/*",
    element: (
      <OffersDataProvider>
        <OfferDetail />
      </OffersDataProvider>
    ),
  },
  {
    path: "/entreprises",
    element: (
      <CompanysDataProvider>
        <Company />
      </CompanysDataProvider>
    ),
  },
  {
    path: "/entreprises/*",
    element: <CompanyDetail />,
  },
  {
    path: "/quiz",
    element: <Quiz />,
  },
  {
    path: "/contactez-nous",
    element: <Contact />,
  },
]);

export default Router;
