import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { REACT_APP_API_URL } from "../components/Variables";

const CompanysDataContext = createContext();

export const CompanysDataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_URL}/getAllCompanys`
      );
      const realData = response.data.filter(
        (item) => item.name !== "TimeoutError"
      );
      setData(realData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CompanysDataContext.Provider value={{ data, error }}>
      {children}
    </CompanysDataContext.Provider>
  );
};

export const useCompanysData = () => {
  const context = useContext(CompanysDataContext);
  if (!context) {
    throw new Error(
      "useCompanysData must be used within a CompanysDataProvider"
    );
  }
  return context;
};
