import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  BOX_SHADOW,
  PRIMARY_COLOR,
  REACT_APP_API_URL,
  TEXT_COLOR_2,
  WHITE_COLOR,
} from "../components/Variables";
import CompanyOffers from "../components/elements/CompanyOffers";
import NoData from "../components/elements/NoData";
import {
  Button,
  InputText,
  Section,
  TruncateText,
} from "../components/elements/SmallElements";
import WeeksOffers from "../components/elements/WeeksOffers";
import Bottom from "../components/shapes/Bottom";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";
import { UseScrollToTop } from "../components/elements/UseScrollToTop";

export default function Quiz() {
  UseScrollToTop();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${REACT_APP_API_URL}/getAllQuiz`
        );
        const realData = response.data.filter(
          (item) => item.name !== "TimeoutError"
        );
        setData(realData);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
  );
  return (
    <>
      <Header home={false} title="Quiz" page="Quiz" />
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="row align-items-start justify-content-between mb-4">
                <div className="col-12 col-sm-7 col-xl-8">
                  <div className="TEXT_COLOR_1 fs-5">
                    Tous les{" "}
                    <b className="TEXT_PRIMARY">{filteredData.length}</b> quiz
                    trouvés
                  </div>
                </div>
                <div className="col-12 col-sm-5 col-xl-4 mt-3 mt-sm-0">
                  <InputText
                    type="search"
                    placeholder="Rechercher..."
                    className="w-100"
                    value={searchValue}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div className="row">
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <motion.div
                      key={index}
                      className="col-12 col-md-6 col-lg-12 col-xl-6 mb-4"
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{
                        scale: 1,
                        opacity: 1,
                        transition: {
                          ease: [0.68, -0.55, 0.265, 1.55],
                          duration: 0.3,
                        },
                      }}
                    >
                      <QuizBox>
                        <img
                          src={item.image}
                          alt="Logo"
                          width="80"
                          height="80"
                          loading="lazy"
                          decoding="async"
                          className="lazy-img"
                        />
                        <div>{TruncateText(item.title, 15)}</div>
                        <p>{item.info}</p>
                        <p>Nombre de participants : {item.number}</p>
                        <p className="mb-4">Note moyenne : {item.Average}</p>
                        <Button
                          text="Lancer le test"
                          url={item.url}
                          newTab="yes"
                        />
                      </QuizBox>
                    </motion.div>
                  ))
                ) : (
                  <div className="py-5 my-5 ">
                    <NoData text={`aucun Quiz avec ce nom "${searchValue}"`} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              <SideBar>
                <div className="row">
                  <div className="col-12  mb-4">
                    <CompanysDataProvider>
                      <CompanyOffers />
                    </CompanysDataProvider>
                  </div>
                  <div className="col-12">
                    <OffersDataProvider>
                      <WeeksOffers />
                    </OffersDataProvider>
                  </div>
                </div>
              </SideBar>
            </div>
          </div>
        </div>
        <Bottom />
      </Section>
      <Footer />
    </>
  );
}

const QuizBox = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 50px;
  padding: 40px 20px 35px;
  text-align: center;
  img {
    border-radius: 50%;
    -webkit-box-shadow: ${BOX_SHADOW};
    -moz-box-shadow: ${BOX_SHADOW};
    box-shadow: ${BOX_SHADOW};
  }
  div {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  a {
    font-size: 15px;
    color: ${PRIMARY_COLOR};
    padding: 4px 20px;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.4s ease;
  }
  p {
    margin-bottom: 0px;
    color: ${TEXT_COLOR_2};
  }
  a:hover,
  a:focus {
    border: 1px solid ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    background-color: ${PRIMARY_COLOR};
  }
`;

const SideBar = styled.div`
  position: sticky;
  top: 20px;
`;
